const CustomTooltip = (props) => {
  const { active, payload, label, legendName } = props;
  if (active && payload && payload.length) {
    const unit = getUnit(legendName)
    return (
      <div className="custom-tooltip">
        <p className="custom-tooltip__label">{`${label}`}</p>
        <p className="custom-tooltip__value">{legendName}: {legendName === 'Usage' ? '$' : ''}{payload[0].value} {unit}</p>
      </div>
    );
  }

  return null;
};

const getUnit = (legendName) => {
  switch (legendName) {
    case 'URL Hits':
      return 'K hits';
    case 'URL Volume':
      return 'GB';
    case 'URL Viewers':
      return ''
    default:
      return '';
  }
}

export default CustomTooltip;