// import {client} from "@gradio/client";
import {toast} from "react-toastify";
import {SDAutomatic, SDXL} from "../../constants/sd-models";
import {get, post} from "../../utils/fetch";
import {Hosts, RequestState} from "../../constants";
import _ from "lodash";
import {getUrlFromPath} from "./Showcases/ShowcaseSketchApi";

let eventSource = null;
let vitonWc = null;


const processResponse = (response) => {
  if (!response) return null;
  let processedResponse = response.replace(/<\|im_end\|>/g, '');
  processedResponse = processedResponse.replace(/<\|im_start\|>/g, '');

  // Remove the user's question from the assistant's response
  const userQuestionIndex = processedResponse.lastIndexOf('user\n');
  if (userQuestionIndex !== -1) {
    processedResponse = processedResponse.substring(0, userQuestionIndex);
  }

  // Check if the response is cut off in the middle of a sentence
  const lastChar = processedResponse[processedResponse.length - 1];
  if (lastChar !== '.' && lastChar !== '?' && lastChar !== '!') {
    // Find the last occurrence of a sentence end
    const lastPeriod = processedResponse.lastIndexOf('.');
    const lastQuestionMark = processedResponse.lastIndexOf('?');
    const lastExclamationMark = processedResponse.lastIndexOf('!');
    const lastSentenceEnd = Math.max(lastPeriod, lastQuestionMark, lastExclamationMark);

    // Trim the response to the last complete sentence
    if (lastSentenceEnd !== -1) {
      processedResponse = processedResponse.substring(0, lastSentenceEnd + 1);
    }
  }

  return processedResponse;
};

const FETCH_SHOWCASES = 'FETCH_SHOWCASES';

export const selectShowcases = (state) => state.models.showcases

export const AIShowcase = {
  actions: {

    fetchShowcases: () => async (dispatch, getState) => {
      let response = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/showcase/list`,
        action: FETCH_SHOWCASES,
        dispatch,
      })

      return response?.body?.showcases;
    },

    createCompletion: (model, params, regenerating = false) => async (dispatch, getState) => {
      const id = Math.random().toString(36).substring(2, 12);

      gtag('event', `chatbot_user_generate_text`, {
        event_category: 'chatbot',
        event_label: `Chatbot - User Generate Text`,
        value: params
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": id,
        "name": "generate_text_start",
        "category": "chatbot",
        "label": "Generate Text Start",
        "model": model.name,
        "params": JSON.stringify({...params, regenerating}),
      }))

      const body = {
        model: model.name,
        ...params,
        messages: [
          {"role": "system", "content": "You are a helpful assistant."},
          ...params.messages
        ]
      };

      const response = await fetch(`${model.urls}/v1/chat/completions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body),
      });

      const json = await response?.json();

      let text = processResponse(json?.choices?.[0]?.message?.content);

      if (json?.choices?.[0]?.finish_reason === "length") {
        text = text + '...';
      }

      gtag('event', `chatbot_text_generated`, {
        event_category: 'chatbot',
        event_label: `Chatbot - Text Generated`,
        value: {
          ...params, text, regenerating
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": id,
        "name": "generate_text_done",
        "category": "chatbot",
        "label": "Generate Text Done",
        "model": model.name,
        "params": JSON.stringify({...params, text, regenerating}),
      }))

      return text;
    },

    stableDiffusionXLPredict: (params, modelData) => async (dispatch, getState) => {
      const sdUrl = modelData.urls;
      const sessionHash = Math.random().toString(36).substring(2, 12);

      await fetch(`${sdUrl}/queue/join`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          data: [
            params.prompt,
            params.strength,
            params.guidanceScale,
            params.steps,
            params.seed,
          ],
          session_hash: sessionHash,
          fn_index: 1,
          trigger_id: 4,
          event_data: null
        }),
      });

      return new Promise((resolve, reject) => {
        let result = null;
        const eventSource = new EventSource(`${sdUrl}/queue/data?session_hash=${sessionHash}`);
        eventSource.onmessage = (e) => {
          const data = JSON.parse(e.data)
          if (data.msg === 'process_completed') {
            if (!data.success) {
              reject('Failed to generate')
              return;
            }
            result = `${sdUrl}/file=${data.output.data[0].path}`;
            eventSource.close()
            resolve(result);
          }
        };
        eventSource.onerror = (err) => {
          console.log('onerror' + err)
          toast.error(err.message)
          resolve(null)
        };
      });
    },

    stableDiffusionAutomaticPredict: (params, modelData) => async (dispatch, getState) => {
      const urls = modelData.urls.split(',');

      const sdUrl = urls[Math.floor(Math.random() * urls.length)];

      const body = {
        "prompt": params.prompt,
        "steps": params.steps,
        "seed": params.seed,
        "cfg_scale": params.guidanceScale,
        "sampler_index": "Euler a",
        "width": params.width,
        "height": params.height
      };

      const response = await fetch(`${sdUrl}/sdapi/v1/txt2img`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      const json = await response?.json();

      return new Promise((resolve, reject) => {
        const base64Image = json.images[0];
        const img = new Image();
        img.src = `data:image/png;base64,${base64Image}`;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            return resolve(url);
          }, 'image/png');
        };

        img.onerror = (err) => {
          console.log('onerror' + err)
          toast.error(err.message)
          resolve(null)
        }
      });
    },


    generateImage: (pageName, currentModel, message, regenerating, showcase) => async (dispatch, getState) => {
      const id = Math.random().toString(36).substring(2, 12);

      gtag('event', `showcase_user_generate_image`, {
        event_category: 'text_to_image',
        event_label: `Showcase - User Generate Image`,
        value: {
          model: currentModel.name,
          message,
          regenerating
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": id,
        "name": "generate_image_start",
        "category": "text_to_image",
        "label": "Generate image start",
        "model": currentModel.name,
        "params": JSON.stringify({...message, regenerating}),
      }))

      let result = null;
      const modelData = _.find(showcase.models, model => model.name === currentModel.name)
      if (currentModel.name === SDXL.name) {
        result = await dispatch(AIShowcase.actions.stableDiffusionXLPredict(message, modelData))
      } else if (currentModel.name === SDAutomatic.name) {
        result = await dispatch(AIShowcase.actions.stableDiffusionAutomaticPredict(message, modelData))
      }

      gtag('event', `showcase_image_generated`, {
        event_category: 'text_to_image',
        event_label: `Showcase Image Generated`,
        value: {
          message,
          model: currentModel.name,
          regenerating
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": id,
        "name": "generate_image_done",
        "category": "text_to_image",
        "label": "Generate image done",
        "model": currentModel.name,
        "params": JSON.stringify({...message, regenerating}),
        "image_url": result
      }))

      return result
    },

    generateImprovedImagePrompt: (prompt) => async (dispatch, getState) => {
      const chatbotModel = _.find(getState().models.showcases, showcase => showcase.name === 'Chatbot').models[0];
      return await dispatch(AIShowcase.actions.createCompletion(chatbotModel, {
        messages: [
          {
            "role": "system",
            "content": "You are an expert prompt engineer. Your task is to transform user inputs into highly detailed and vivid text prompts suitable for generating images using the Stable Diffusion model. Respond with the improved prompt only. Do not include any additional text, explanations, or prefixes."
          },
          {"role": "user", "content": prompt}
        ],
        "max_tokens": 200,
        "temperature": 0.4
      }))
    },


    uploadImageForImageToVideo: (file, url) => async (dispatch, getState) => {
      const formData = new FormData();
      formData.append('files', file, file.name);

      try {
        const response = await fetch(`${url}/upload`, {
          method: 'POST',
          body: formData
        })
        const json = await response?.json();
        return json?.[0]
      } catch (e) {
        console.log(e)
      }
    },

    imageToVideoPredict: (params, onEvent, model) => async (dispatch, getState) => {
      const sessionHash = Math.random().toString(36).substring(2, 12);
      const url = model.urls;
      const imageUrl = `${url}/file=${params.image.path}`

      const startTime = new Date().getTime()

      gtag('event', `generate_image_to_video`, {
        event_category: "Image to video",
        event_label: `Generate image to video`,
        value: {
          image_url: imageUrl
        }
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": "generate_video_start",
        "category": "image_to_video",
        "label": "Generate video start",
        "model": model.name,
        "params": JSON.stringify(params),
        "image_url": imageUrl
      }))

      const response = await fetch(`${url}/queue/join`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          data: [
            {...params.image, url: imageUrl},
            params.seed,
            false,
            params.motion_bucket_id,
            params.frames_per_second,
          ],
          session_hash: sessionHash,
          fn_index: 0,
          trigger_id: 14,
          event_data: null
        }),
      });
      const json = await response?.json();

      if (!response.ok) {
        let errorMessage = json?.detail || 'Failed to generate'
        if (errorMessage.toLowerCase().includes('queue is full')) {
          errorMessage = 'The video generation queue is currently full. Please try again later.'
        }

        onEvent({msg: 'error', error: errorMessage})
        return
      }

      if (eventSource) {
        eventSource.close()
        eventSource = null
      }

      eventSource = new EventSource(`${url}/queue/data?session_hash=${sessionHash}`);
      eventSource.onmessage = (e) => {
        const data = JSON.parse(e.data)
        onEvent(data)

        if (data.msg === 'process_completed') {
          params.duration = (new Date().getTime() - startTime) / 1000

          if (data.success) {
            const videoUrl = `${url}/file=${data.output.data[0].video.path}`;

            gtag('event', `image_to_video_generated`, {
              event_category: "Image to video",
              event_label: `Image to video generated`,
              value: {
                image_url: imageUrl,
                video_url: videoUrl
              }
            })

            dispatch(AIShowcase.actions.reportStats({
              "id": sessionHash,
              "name": "generate_video_done",
              "category": "image_to_video",
              "label": "Generate video done",
              "model": model.name,
              "params": JSON.stringify(params),
              "image_url": imageUrl,
              "video_url": videoUrl
            }))
          }
          eventSource.close()
        }
      };

      eventSource.onerror = (e) => {
        if (e.readyState === EventSource.CONNECTING) {
          return
        }

        dispatch(AIShowcase.actions.reportStats({
          "id": sessionHash,
          "name": "generate_video_error",
          "category": "image_to_video",
          "label": "Generate video error",
          "model": model.name,
          "params": JSON.stringify(params),
          "image_url": imageUrl,
          "error": "Failed to generate the video."
        }))

        onEvent({msg: 'error', error: 'Failed to generate the video. Try again later.'})
        eventSource.close()
      };
    },

    stopImageToVideoGeneration: () => async (dispatch, getState) => {
      if (eventSource) {
        eventSource.close()
        eventSource = null
      }
    },

    fetchExploreTextToImage: () => async (dispatch, getState) => {
      const response = await fetch("https://sheet2api.com/v1/RSBMaYwSHCof/thetaedgecloud-ai-showcase-endpoints")
      const json = await response.json()
      return json;
    },

    vitonPredict: (url, params, onEvent) => async (dispatch, getState) => {
      const wsUrl = url.replace('https://', 'wss://')
      const sessionHash = Math.random().toString(36).substring(2, 12);

      gtag('event', `generate_viton`, {
        event_category: "Virtual try-on",
        event_label: `Generate virtual try-on`,
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": "generate_viton",
        "category": "viton",
        "label": "Generate virtual-try-on",
      }))

      if (vitonWc) {
        vitonWc.close()
        vitonWc = null
      }

      vitonWc = new WebSocket(`${wsUrl}/queue/join`);

      vitonWc.onopen = (e) => {
        vitonWc.send(JSON.stringify({fn_index: 2, session_hash: sessionHash}))
      };

      vitonWc.onmessage = (e) => {
        const data = JSON.parse(e.data);
        onEvent(data);

        switch (data.msg) {
          case 'send_data':
            vitonWc.send(JSON.stringify({
              fn_index: 2, session_hash: sessionHash, event_data: null,
              data: [
                params.model,
                params.garment,
                params.steps,
                false
              ]
            }))
            break;
          case 'process_completed':
            vitonWc.close()

            gtag('event', `viton_generated`, {
              event_category: "Virtual try-on",
              event_label: `Virtual try-on generated`,
            })


            dispatch(AIShowcase.actions.reportStats({
              "id": sessionHash,
              "name": "generate_viton_done",
              "category": "viton",
              "label": "Generate virtual-try-on done",
            }))
            break;
        }
      };
      vitonWc.onerror = (e) => {
        console.log('WebSocket error');
        console.log(e)
        dispatch(AIShowcase.actions.reportStats({
          "id": sessionHash,
          "name": "generate_viton_error",
          "category": "viton",
          "label": "Generate virtual-try-on error",
          "params": JSON.stringify(params),
          "error": "Failed to generate the virtual try-on."
        }))
        onEvent({msg: 'error', error: 'Failed to generate. Try again later.'})
        vitonWc.close()
      };
      vitonWc.onclose = (e) => {
      };

    },

    stopVitonGeneration: () => async (dispatch, getState) => {
      if (vitonWc) {
        vitonWc.close()
        vitonWc = null
      }
    },

    vitonGetGalleryImage: (url, galleryIndex, itemIndex) => async (dispatch, getState) => {
      const sessionHash = Math.random().toString(36).substring(2, 12);
      const response = await fetch(`${url}/run/predict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "data": [
            itemIndex,
          ],
          "event_data": null,
          "fn_index": galleryIndex,
          "session_hash": sessionHash
        }),
      });
      const json = await response?.json();

      if (json?.data?.length > 0) {
        return json.data[0]
      }
    },

    uploadImage: (file, url) => async (dispatch, getState) => {
      const formData = new FormData();
      formData.append('files', file, file.name);

      try {
        const response = await fetch(`${url}/upload`, {
          method: 'POST',
          body: formData
        })
        const json = await response?.json();
        return json?.[0]
        // return `${url}/file=${json?.[0]}`
      } catch (e) {
        console.log(e)
      }
    },

    artStyleTransferPredict: (url, params, onEvent) => async (dispatch, getState) => {
      const sessionHash = Math.random().toString(36).substring(2, 12);

      gtag('event', `generate_art_style_transfer`, {
        event_category: "Art style transfer",
        event_label: `Generate art style transfer`,
        value: params
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": "generate_art_style_transfer",
        "category": "art_style_transfer",
        "label": "Generate art style transfer",
        "params": JSON.stringify(params),
      }))

      const response = await fetch(`${url}/queue/join`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "data": [
            {
              "is_stream": false,
              "meta": {
                "_type": "gradio.FileData"
              },
              "mime_type": null,
              "orig_name": "image",
              "path": params.imageUrl,
              "size": null,
              "url": params.imageUrl
            },
            {
              "is_stream": false,
              "meta": {
                "_type": "gradio.FileData"
              },
              "mime_type": null,
              "orig_name": "art style",
              "path": params.artStyleUrl,
              "size": null,
              "url": params.artStyleUrl
            }
          ],
          "event_data": null,
          "fn_index": 11,
          "session_hash": sessionHash,
          "trigger_id": 15
        }),
      });
      const json = await response?.json();

      if (!response.ok) {
        let errorMessage = json?.detail || 'Failed to generate'
        if (errorMessage.toLowerCase().includes('queue is full')) {
          errorMessage = 'The art style transfer queue is currently full. Please try again later.'
        }

        onEvent({msg: 'error', error: errorMessage})
        return
      }

      if (eventSource) {
        eventSource.close()
        eventSource = null
      }

      eventSource = new EventSource(`${url}/queue/data?session_hash=${sessionHash}`);
      eventSource.onmessage = (e) => {
        const data = JSON.parse(e.data)
        onEvent(data)

        if (data.msg === 'process_completed') {

          if (data.success) {
            console.log(data)
            const imageUrl = data.output.data[0].url;

            gtag('event', `art_style_transfer_generated`, {
              event_category: "Art style transfer",
              event_label: `Art style transfer generated`,
              value: {
                image_url: imageUrl,
              }
            })


            dispatch(AIShowcase.actions.reportStats({
              "id": sessionHash,
              "name": "generate_art_style_transfer_done",
              "category": "art_style_transfer",
              "label": "Generate art style transfer done",
              "params": JSON.stringify(params),
              "image_url": imageUrl,
            }))
          }
          eventSource.close()
        }
      };

      eventSource.onerror = (e) => {
        if (e.readyState === EventSource.CONNECTING) {
          return
        }

        dispatch(AIShowcase.actions.reportStats({
          "id": sessionHash,
          "name": "generate_art_style_transfer_error",
          "category": "art_style_transfer",
          "label": "Generate art style transfer error",
          "params": JSON.stringify(params),
          "error": "Failed to generate the art style transfer."
        }))

        onEvent({msg: 'error', error: 'Failed to generate the art style transfer. Try again later.'})
        eventSource.close()
      };
    },

    stopArtStyleTransferGeneration: () => async (dispatch, getState) => {
      if (eventSource) {
        eventSource.close()
        eventSource = null
      }
    },

    imageToImagePredict: (modelUrl, modelData, images, prompts, rangeInputs, onEvent) => async (dispatch, getState) => {
      const sessionHash = Math.random().toString(36).substring(2, 12);

      gtag('event', `generate_${modelData.name}`, {
        event_category: modelData.label,
        event_label: `Generate ${modelData.label}`,
        value: images
      })

      dispatch(AIShowcase.actions.reportStats({
        "id": sessionHash,
        "name": `generate_${modelData.name}`,
        "category": modelData.label,
        "label": `Generate ${modelData.label}`,
        "params": JSON.stringify(images),
      }))

      const response = await fetch(`${modelUrl}/queue/join`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "data": modelData.name === 'in_painting'
            ? getInPaintingArray(modelUrl, images, prompts, rangeInputs)
            : modelData.drawing_enabled
              ? [getCompositeArray(modelUrl, images)]
              : getGalleryArray(images),
          "event_data": null,
          "fn_index": modelData.fn_index,
          "session_hash": sessionHash,
          "trigger_id": modelData.trigger_id
        }),
      });
      const json = await response?.json();

      if (!response.ok) {
        let errorMessage = json?.detail || 'Failed to generate'
        if (errorMessage && typeof errorMessage === 'string' && errorMessage.toLowerCase().includes('queue is full')) {
          errorMessage = 'The queue is currently full. Please try again later.'
        }

        onEvent({msg: 'error', error: errorMessage})
        return
      }

      if (eventSource) {
        eventSource.close()
        eventSource = null
      }

      eventSource = new EventSource(`${modelUrl}/queue/data?session_hash=${sessionHash}`);
      eventSource.onmessage = (e) => {
        const data = JSON.parse(e.data)
        onEvent(data)

        if (data.msg === 'process_completed') {

          if (data.success) {
            console.log(data)
            const imageUrl = data.output.data[0].url;

            gtag('event', `${modelData.label}_generated`, {
              event_category: modelData.label,
              event_label: `Generated ${modelData.label}`,
              value: {
                image_url: imageUrl,
              }
            })


            dispatch(AIShowcase.actions.reportStats({
              "id": sessionHash,
              "name": `generate_${modelData.name}_done`,
              "category": modelData.label,
              "label": `Generated ${modelData.label} done`,
              "params": JSON.stringify(images),
              "image_url": imageUrl,
            }))
          }
          eventSource.close()
        }
      };

      eventSource.onerror = (e) => {
        if (e.readyState === EventSource.CONNECTING) {
          return
        }

        dispatch(AIShowcase.actions.reportStats({
          "id": sessionHash,
          "name": `generate_${modelData.name}_error`,
          "category": modelData.label,
          "label": `Generated ${modelData.label} error`,
          "params": JSON.stringify(images),
          "error": `Failed to generate the ${modelData.label}`
        }))

        onEvent({msg: 'error', error: 'Failed to generate the art style transfer. Try again later.'})
        eventSource.close()
      };
    },

    stopGeneration: () => async (dispatch, getState) => {
      if (eventSource) {
        eventSource.close()
        eventSource = null
      }
    },

    reportStats: (params) => async (dispatch, getState) => {
      const response = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/utilization_stats`,
        body: {
          table: 'showcase_analytics',
          ...params
        },
      })
    }

  },
  spec: {
    showcases: null,
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (type === FETCH_SHOWCASES) {
      return {
        ...state,
        showcases: body.showcases,
      }
    }

    return state;
  }
}

const getGalleryArray = (images) => {
  return _.map(images, (image, index) => ({
    "is_stream": false,
    "meta": {
      "_type": "gradio.FileData"
    },
    "mime_type": null,
    "orig_name": `image_${index}`,
    "path": image,
    "url": image
  }))
}

const getCompositeArray = (apiUrl, images) => {
  return {
    "background": {
      "meta": {
        "_type": "gradio.FileData"
      },
      "mime_type": "",
      "orig_name": "background.png",
      "path": images.background,
      "size": null,
      "url": getUrlFromPath(images.background, apiUrl),
    },
    "composite": {
      "meta": {
        "_type": "gradio.FileData"
      },
      "mime_type": "",
      "orig_name": "composite.png",
      "path": images.composite,
      "size": null,
      "url": getUrlFromPath(images.composite, apiUrl),
    },
    "layers": [
      {
        "meta": {
          "_type": "gradio.FileData"
        },
        "mime_type": "",
        "orig_name": "layer_0.png",
        "path": images.layer_0,
        "size": null,
        "url": getUrlFromPath(images.layer_0, apiUrl),
      }
    ]
  }
}

const getInPaintingArray = (apiUrl, images, prompts, rangeInputs) => {

  const compositeArray = getCompositeArray(apiUrl, images)

  return [
    compositeArray,
    _.find(prompts, prompt => prompt.name === 'prompt').value,
    _.find(prompts, prompt => prompt.name === 'negative_prompt').value,
    "",
    "",
    1,
    45,
    7.5,
    _.find(rangeInputs, rangeInput => rangeInput.name === "seed").value,
    null,
    1,
    1,
    "",
    "",
    "",
    "",
    false,
    compositeArray,
    null,
    0.5
  ]
}