import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ServiceSidebarItem } from "../../../components/ServiceSidebarItem";
import { ServiceSidebarSubItem } from '../../../components/ServiceSidebarSubItem';
import { mergeUrl } from "../../../utils/url";
import { Urls } from "../../../constants";
import { PrototypingIcon, ServingIcon, TrainingIcon } from "../../../components/Svg";
import JupyterNotebookPage from './JupyterNotebookPage';
import ModelExplorerPage from './ModelExplorerPage';
import ModelDeploymentDetailPage from './ModelDeploymentDetailPage';
import ModelDeploymentsPage from './ModelDeploymentsPage';
import { selectCurrentUserId } from "../../../store/models/User";
import { selectCurrentProject, selectCurrentProjectId } from "../../../store/models/Project";
import Ai, { selectAllstandardTemplates } from '../../../store/models/Ai';

import { createSelector } from "reselect";
import GPUMarketingPage from './GPUMarketingPage';
import { selectCurrentOrg } from '../../../store/models/Organization';
import { Overlay } from '../../../components/Overlay';
import LlmDetailPage from './LlmdetailPage';
import GPUNodePage from './GPUNodePage';
import GpuNodeDetailPage from './GpuNodeDetailPage';

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentProjectId,
  selectCurrentProject,
  selectCurrentOrg,
  (userId, currentProjectId, currentProject, currentOrg) => {
    return {
      userId,
      currentProjectId: currentProjectId || 'service',
      currentProject,
      currentOrg
    }
  }
);

function AiServicesPage() {
  const dispatch = useDispatch();
  const { selectedTab } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { userId, currentProjectId, currentProject, currentOrg } = useSelector(state => selector(state));
  // console.log('currentOrg:', currentOrg)
  const standardTemplates = useSelector(state => selectAllstandardTemplates(state));

  // console.log('selectedTab:', selectedTab)
  // console.log('userId:', userId, ', currentProjectId:', currentProjectId, ", currentProject:", currentProject,)
  // console.log('standardTemplates:', standardTemplates)

  useEffect(() => {
    dispatch(Ai.actions.fetchstandardTemplates());
    dispatch(Ai.actions.fetchPrototypingTemplates());
    dispatch(Ai.actions.fetchTrainingTemplates());
    dispatch(Ai.actions.fetchVMs());
    // if (_.isNil(userId)) return;
    // dispatch(Ai.actions.fetchCustomTemplates(currentProjectId));
  }, []);

  useEffect(() => {
    if (_.isNil(userId)) return;
    dispatch(Ai.actions.fetchCustomTemplates(currentProjectId, 1, 8));
  }, [])


  return (<div className={'ServiceContainer'}>
    {(!userId || (currentOrg && !currentOrg.disabled)) && <>
      <div className='ServiceSidebar'>
        <div className={'ServiceSidebar__title'}>THETA AI SERVICES</div>
        <ServiceSidebarItem title={'Serving'}
          active={selectedTab === 'model-explorer' || selectedTab === 'model-deployments'
            || selectedTab === 'model-deployment-detail' || selectedTab === 'llm-detail'}
          link={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId: currentProjectId })}
          icon={<ServingIcon />}
          className='ai' />
        <ServiceSidebarSubItem title={'Model explorer'}
          active={selectedTab === 'model-explorer'}
          link={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId: currentProjectId })} />
        <ServiceSidebarSubItem title={'Model deployments'}
          active={selectedTab === 'model-deployments' || selectedTab === 'model-deployment-detail'
            || selectedTab === 'llm-detail'}
          link={mergeUrl(Urls.AI_MODEL_DEPLOYMENTS, { projectId: currentProjectId })} />

        <ServiceSidebarItem title={'Prototyping'}
          active={selectedTab === 'jupyter-notebook'}
          link={mergeUrl(Urls.AI_JUPYTER_NOTEBOOK, { projectId: currentProjectId })}
          icon={<PrototypingIcon />}
          className='ai' />
        <ServiceSidebarSubItem title={'Jupyter notebook'}
          active={selectedTab === 'jupyter-notebook'}
          link={mergeUrl(Urls.AI_JUPYTER_NOTEBOOK, { projectId: currentProjectId })} />

        <ServiceSidebarItem title={'Training'}
          active={selectedTab === 'gpu-node' || selectedTab === 'gpu-node-detail'}
          link={mergeUrl(Urls.AI_GPU_NODE, { projectId: currentProjectId })}
          icon={<TrainingIcon />}
          className='ai' />
        <ServiceSidebarSubItem title={'GPU node'}
          active={selectedTab === 'gpu-node' || selectedTab === 'gpu-node-detail'}
          link={mergeUrl(Urls.AI_GPU_NODE, { projectId: currentProjectId })} />

        {/* <ServiceSidebarItem title={'Training'}
        active={selectedTab === 'training'}
        disabled={true}
        icon={<TrainingIcon />}
        className='ai' />
      <ServiceSidebarSubItem title={'Hyper parameter tuning'}
        active={selectedTab === 'hyper-parameter-tuning'}
        link={mergeUrl(Urls.AI_HYPER_PARAMETER_TUNING, { projectId:currentProjectId })} />
      <ServiceSidebarSubItem title={'Neural architecture search'}
        active={selectedTab === 'neural-architecture-search'}
        link={mergeUrl(Urls.AI_NEURAL_ARCHITECTURE_SEARCH, { projectId:currentProjectId })} />
      <ServiceSidebarSubItem title={'Model fine-tuning'}
        active={selectedTab === 'model-fine-tuning'}
        link={mergeUrl(Urls.AI_MODEL_FINE_TUNING, { projectId:currentProjectId })} /> */}

        {/* <ServiceSidebarItem title={'Clusters'}
        active={selectedTab === 'clusters'}
        disabled={true}
        icon={<ClustersIcon />}
        className='ai' />
      <ServiceSidebarSubItem title={'Ray cluster'}
        active={selectedTab === 'ray-cluster'}
        link={mergeUrl(Urls.AI_RAY_CLUSTER, { projectId:currentProjectId })} />
      <ServiceSidebarSubItem title={'Raw machines'}
        active={selectedTab === 'raw-machines'}
        link={mergeUrl(Urls.AI_RAW_MACHINES, { projectId:currentProjectId })} /> */}

        {/* <ServiceSidebarItem title={'GPU Marketplace'}
        active={selectedTab === 'gpu-marketplace'}
        icon={<GPUIcon />}
        className='ai'
        link={mergeUrl(Urls.AI_GPU_MARKETPLACE, { projectId:currentProjectId })} />
      <ServiceSidebarSubItem title={'Theta Edge Nodes'}
        active={selectedTab === 'theta-edge-nodes'}
        link={mergeUrl(Urls.AI_THETA_EDGE_NODES, { projectId:currentProjectId })} />
      <ServiceSidebarSubItem title={'Cloud Partners'}
        active={selectedTab === 'cloud-partners'}
        link={mergeUrl(Urls.AI_CLOUD_PARTNERS, { projectId:currentProjectId })} />
      <ServiceSidebarSubItem title={'Data Center Partners'}
        active={selectedTab === 'data-center-partners'}
        link={mergeUrl(Urls.AI_DATA_CENTER_PARTNERS, { projectId:currentProjectId })} />
      <ServiceSidebarSubItem title={'Distributed Node Partners'}
        active={selectedTab === 'distributed-node-partners'}
        link={mergeUrl(Urls.AI_DISTRIBUTED_NODE_PARTNERS, { projectId:currentProjectId })} /> */}
      </div>
      <div className={'ServicePage'}>
        {selectedTab === 'jupyter-notebook' && <JupyterNotebookPage />}
        {selectedTab === 'model-deployments' && <ModelDeploymentsPage />}
        {selectedTab === 'llm-detail' && <LlmDetailPage />}
        {selectedTab === 'model-explorer' && <ModelExplorerPage />}
        {selectedTab === 'model-deployment-detail' && <ModelDeploymentDetailPage />}
        {selectedTab === 'gpu-marketplace' && <GPUMarketingPage />}
        {selectedTab === 'gpu-node' && <GPUNodePage />}
        {selectedTab === 'gpu-node-detail' && <GpuNodeDetailPage />}
      </div>
    </>}
    {currentOrg && currentOrg.suspended && <Overlay type={'suspended'} />}
    {currentOrg && currentOrg.disabled && <Overlay type={'disabled'} />}
  </div>
  );
}

export default AiServicesPage;
