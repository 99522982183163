import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import Ai, { selectAllVMs, selectTrainingTemplate } from '../store/models/Ai';
import { getVMOptions, generateRandomId, formatPrice, getErrorMsg } from '../utils';
import { selectCurrentProjectId } from '../store/models/Project';
import { ModalTypes } from '../constants';
import { selectCurrentUser, selectCurrentUserId } from '../store/models/User';
import UIState from '../store/UIState';
import { toast } from "react-toastify";
import { selectCurrentOrgId } from '../store/models/Organization';
import Tooltip from '../components/Tooltip';
import { QuestionMarkIcon } from '../components/Svg';

const selectStyles = {
  container: (styles, { isDisabled }) => ({
    ...styles,
    flex: 1,
    height: 40,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    ':hover': {
      borderColor: 'white',
    },
    '.selected': {
      borderColor: 'white',
    },
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles, color: "#fff" }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#fff', fontWeight: '500' }),
};

export const CreateGPUNodeModal = ({ onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const userId = useSelector(state => selectCurrentUserId(state));
  const curUser = useSelector(state => selectCurrentUser(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const trainingTemplate = useSelector(state => selectTrainingTemplate(state));
  const imagesOptions = trainingTemplate ? trainingTemplate.container_images.map(img => ({ label: img, value: img })) : [];
  let VMs = useSelector(state => selectAllVMs(state));
  VMs = Object.keys(VMs).reduce((acc, key) => {
    if (!key.includes('vm_c')) {
      acc[key] = VMs[key];
    }
    return acc;
  }, {});
  let [suggestedVM, setSuggestedVM] = useState(_.get(trainingTemplate, 'suggested_vms.0'));
  let [vmOptions, setVmOptions] = useState(getVMOptions(VMs, suggestedVM));
  let defaultVM = vmOptions.filter(o => o.value === suggestedVM)[0] || vmOptions[0];
  let [selectedVMOption, setSelectedVMOption] = useState(defaultVM);
  let [price, setPrice] = useState(formatPrice(VMs[suggestedVM || vmOptions[0].value].price_hour / 100));
  const [value, setValue] = useState(null); // State to manage the selected value
  const [inputValue, setInputValue] = useState('');
  const defaultName = 'gpunode' + generateRandomId(10);

  const nameRef = useRef();
  const dnameRef = useRef();
  const imageRef = useRef();
  const sshPortRef = useRef();
  const httpPortRef = useRef();
  const vmRef = useRef();
  const sshKeyRef = useRef();

  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
    return newInputValue;
  };

  const handleBlur = () => {
    if (inputValue) {
      // setValue();
      onImageChange({ label: inputValue, value: inputValue });
      setInputValue('');
    }
  };

  const onSubmit = async () => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP))
      return;
    }
    if (!imageRef.current.state.selectValue[0]) {
      imageRef.current.focus();
      setError("Container image can't be empty.");
      return;
    }
    let name = nameRef.current.value;
    let image = _.get(imageRef, 'current.state.selectValue[0].value');
    let sshPort = Number(sshPortRef.current.value);
    if (isNaN(sshPort)) {
      sshPortRef.current.focus();
      setError("SSH port must be a number.");
      return;
    }
    if (!sshPort) {
      sshPortRef.current.focus();
      setError("SSH port can't be empty.");
      return;
    }
    let httpPort = Number(httpPortRef.current.value);
    if (isNaN(httpPort)) {
      httpPortRef.current.focus();
      setError("HTTP port must be a number.");
      return;
    }
    let vm = vmRef.current.state.selectValue[0].value;
    // let pwd = pwdRef.current.value;
    let dname = dnameRef.current.value;
    let sshKey = sshKeyRef.current.value;
    if (!sshKey) {
      sshKeyRef.current.focus();
      setError("SSH key can't be empty.");
      return;
    }
    // console.log('name:', name, ' image:', image)
    // console.log('port:', port, ' vm:', vm)
    // console.log('projectId:', projectId)
    // console.log('pwd:', pwd)
    const imageId = trainingTemplate.id;
    const tags = trainingTemplate.tags;
    if (name.length) {
      if (!/^[A-Za-z0-9]+$/.test(name)) {
        const errorStr = 'Invalid ID. The ID should only contain letters and numbers.'
        setError(errorStr);
        toast.error('Error: ' + errorStr);
        return;
      }
      if (name.length > 20) {
        const errorStr = 'Invalid ID. The length of the ID should be less than or equal to 20.'
        setError(errorStr);
        toast.error('Error: ' + errorStr)
        return;
      }
    }
    setLoading(true);
    setError(null);
    try {
      const deployment = await dispatch(Ai.actions.createDeployment({
        name: name,
        project_id: projectId,
        deployment_image_id: imageId,
        container_image: image || input,
        min_replicas: 1,
        max_replicas: 1,
        vm_id: vm,
        annotations: {
          tags: JSON.stringify(tags),
          nickname: dname
        },
        additional_ports: [sshPort],
        container_port: httpPort,
        env_vars: {
          "SSH_PUBLIC_KEY": sshKey
        },
        require_env_vars: true
      }))
      console.log('deployment:', deployment);
      await dispatch(Ai.actions.fetchGpuNodes(projectId));
      onRequestClose();
    } catch (e) {
      setLoading(false);
      let error = getErrorMsg(e.message, onRequestClose, curUser, orgId, vm)
      setError(error);
      if (error === e.message) {
        toast.error(e.message)
      }
    }
  }

  const onVMChange = (v) => {
    setPrice(formatPrice(VMs[v.value].price_hour / 100));
    setSelectedVMOption(v)
  }

  const onImageChange = (v) => {
    setValue(v);
    const index = trainingTemplate.container_images.findIndex(img => img === v.value);
    console.log('v:', v, '. Index:', index)
    let newSuggestedVM = _.get(trainingTemplate, `suggested_vms.${index}`)
    let newOptions = getVMOptions(VMs, newSuggestedVM)
    if (index > -1) {
      httpPortRef.current.value = _.get(trainingTemplate, 'container_port');
    } else {
      httpPortRef.current.value = '';
    }

    setVmOptions(newOptions);
    setSelectedVMOption(newOptions.filter(o => o.value === newSuggestedVM)[0] || newOptions[0]);
    setPrice(formatPrice(VMs[suggestedVM || newOptions[0].value].price_hour / 100));
    setSuggestedVM(newSuggestedVM);
  }


  return (
    <Modal className={cx("CreateGPUNodeModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle align={'row'}>
          Create New GPU Node with SSH Access
          <Tooltip tooltip={'Open documentation'}>
            <a href="https://docs.thetatoken.org/docs/edgecloud-ai-training-with-gpu-nodes" target='_blank'>
              <QuestionMarkIcon />
            </a>
          </Tooltip>
        </ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className='CreateGPUNodeModal__row'>
          <div className='CreateGPUNodeModal__select-wrap name'>
            <div className='CreateGPUNodeModal__select-label'>GPU Node ID</div>
            <input className={"CreateGPUNodeModal__input"}
              placeholder={'Enter gpu node ID'}
              ref={nameRef}
              defaultValue={defaultName}
              disabled={true}
            />
          </div>
          <div className='CreateGPUNodeModal__select-wrap name'>
            <div className='CreateGPUNodeModal__select-label'>GPU Node Name&nbsp;<div className='text-optional'>(optional)</div></div>
            <input className={"CreateGPUNodeModal__input"}
              placeholder={'Enter gpu node name'}
              ref={dnameRef}
            />
          </div>
        </div>
        <div className='CreateGPUNodeModal__row'>
          <div className='CreateGPUNodeModal__select-wrap image'>
            <div className='CreateGPUNodeModal__select-label'>GPU Node Image</div>
            <CreatableSelect
              options={imagesOptions}
              className={"CreateGPUNodeModal__selector"}
              placeholder={"Container image with sshd, e.g. thetalabsorg/ubuntu-sshd:latest"}
              styles={selectStyles}
              onChange={onImageChange}
              ref={imageRef}
              onInputChange={handleInputChange}
              onBlur={handleBlur} // Handle blur event
              // onMenuClose={handleMenuClose} // Handle menu close event
              value={value} // Controlled value
              inputValue={inputValue} // Controlled input value
            // onBlur={handleOnBlur}
            // inputValue={input}
            // onInputChange={(value, action) => {
            //   if (action?.action == 'input-blur' || action?.action == 'menu-close') {
            //     // setInput(value);
            //     console.log('value:', value)
            //     imageRef.current.selectOption({ label: value, value });
            //     // imageRef.current.selectOption(value);
            //   }
            // }}
            />
            {/* <Select options={imagesOptions}
              className={"CreateGPUNodeModal__selector"}
              defaultValue={imagesOptions[0]}
              styles={selectStyles}
              placeholder={'Select Image'}
              isDisabled={imagesOptions.length < 2}
              onChange={onImageChange}
              ref={imageRef}
            /> */}
          </div>
        </div>
        <div className='CreateGPUNodeModal__row'>
          <div className='CreateGPUNodeModal__select-wrap ports'>
            <div className='CreateGPUNodeModal__select-label'>SSH Port</div>
            <input className={"CreateGPUNodeModal__input"}
              placeholder={'Enter SSH port'}
              ref={sshPortRef}
              defaultValue={22}
            />
            <div className='CreateGPUNodeModal__select-label'>HTTP Port&nbsp;<div className='text-optional'>(optional)</div></div>
            <input className={"CreateGPUNodeModal__input"}
              placeholder={'Enter HTTP port'}
              ref={httpPortRef}
            />
          </div>
          <div className='CreateGPUNodeModal__select-wrap ssh'>
            <div className='CreateGPUNodeModal__select-label'>SSH Public Key. How to&nbsp;<a href="https://git-scm.com/book/en/v2/Git-on-the-Server-Generating-Your-SSH-Public-Key"
              target='_blank'>generate a public key.</a></div>
            <textarea className={"CreateGPUNodeModal__textarea"}
              placeholder={'ssh-rsa AAAAB...... (content of your ~/.ssh/id_rsa.pub file)'}
              ref={sshKeyRef}
            />
          </div>
        </div>
        <div className='CreateGPUNodeModal__row'>
          <div className='CreateGPUNodeModal__select-wrap vms'>
            <div className='CreateGPUNodeModal__select-label'>Machine type</div>
            <Select options={vmOptions}
              className={"CreateGPUNodeModal__selector"}
              value={selectedVMOption}
              styles={selectStyles}
              placeholder={'Select Machine type'}
              isDisabled={vmOptions.length < 2}
              onChange={onVMChange}
              ref={vmRef}
              isOptionDisabled={(option) => {
                return option.price < Number(VMs[suggestedVM || vmOptions[0].value].price_hour)
              }}
            />
          </div>
        </div>
        <div className='CreateGPUNodeModal__price-info'>
          <div className='CreateGPUNodeModal__price-info--note'>
            Cost Estimation
          </div>
          <div className='CreateGPUNodeModal__price-info--price'>
            ~ ${price} per hour.
          </div>
        </div>
        {error && <div className={cx("CreateGPUNodeModal__error")}>{error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={"Create GPU Node"} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}