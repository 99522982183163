import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import Modal, {ModalActions, ModalContent, ModalHeader, ModalTitle} from "../components/Modal";
import {Button} from "../components/Button";
import Organization, {selectCurrentOrg, selectOrganizations} from "../store/models/Organization";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import _ from "lodash";
import AppState from "../store/AppState";
import {pushTo} from "../store/history";
import {ModalTypes, Urls} from "../constants";
import Project from "../store/models/Project";
import UIState from "../store/UIState";
import {createSelector} from "reselect";
import {SelectInput} from "../components/form/SelectInput";
import {toast} from "react-toastify";

const selector = createSelector(
  selectOrganizations,
  selectCurrentOrg,
  (organizations, currentOrg) => {
    return {organizations, currentOrg}
  }
)

export const CreateProjectModal = ({onRequestClose}) => {
  const dispatch = useDispatch()
  const {organizations, currentOrg} = useSelector(state => selector(state))

  const {
    register,
    handleSubmit,
    formState
  } = useForm();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [selectedOrg, setSelectedOrg] = useState(currentOrg)
  const orgOptions = _.map(organizations, (org) =>
    ({label: org.name, value: org.id}))

  useEffect(() => {
    dispatch(Organization.actions.fetchUserOrganizations());
  }, []);

  useEffect(() => {
    if (formState.errors.name) {
      setError('Please enter a name')
    } else if (_.isEmpty(formState.errors)) {
      setError(null)
    }
  }, [formState]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(createProject)();
  }

  const createProject = async (body) => {
    setLoading(true);
    setError(null);

    try {
      const project = await dispatch(Project.actions.createProject(body, selectedOrg.id))

      if (selectedOrg.id !== currentOrg.id) {
        await dispatch(AppState.actions.selectCurrentOrgAndProject(selectedOrg.id, project.id))
      } else {
        await dispatch(AppState.actions.selectProject(project.id))
      }
      toast.success('Project created')
      onRequestClose()
      pushTo(Urls.DASHBOARD)
    } catch (e) {
      setLoading(false);
      setError(e.message)
    }
  }

  const onSelectOrg = (org) => {
    setSelectedOrg(_.find(organizations, {id: org.value}))
  }

  return (
    <Modal className={cx("CreateProjectModal")}
           onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>Create New Project</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className={cx("NewFormError")}>{error && error}</div>

        <form onSubmit={onSubmit} className={"NewForm"}>

          <div className={"NewForm__Row NewForm__Row--last"}>

            {/*<div className={"NewForm__InputWrapper"}>*/}
            {/*  <div className={"NewForm__InputLabel"}>Organization</div>*/}
            {/*  <SelectInput options={orgOptions}*/}
            {/*               value={{label: selectedOrg.name, value: selectedOrg.id}}*/}
            {/*               onChange={onSelectOrg} />*/}
            {/*</div>*/}

            <div className={"NewForm__InputTextWrapper "}>
              <div className={"NewForm__InputLabel"}>Project name</div>
              <input name="name"
                     type={"text"}
                     autoFocus={true}
                     placeholder={"Enter a name"}
                     {...register("name", {required: true})} />
            </div>

          </div>

          <ModalActions>
            <Button color={"green"} role={'submit'} loading={loading} title={"Create New Project"}/>
            <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"}/>
          </ModalActions>

        </form>

      </ModalContent>

    </Modal>
  );
}