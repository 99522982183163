import _ from 'lodash';
import { authHeaders, del, get, post, put } from "../../utils/fetch";
import { Hosts, RequestState } from "../../constants";
import { selectCurrentOrgId } from "./Organization";
import { selectCurrentUser, selectCurrentUserId, selectUsers } from "./User";

// ===========================
// ACTIONS
// ===========================
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_ORG_PROJECTS = 'FETCH_ORG_PROJECTS';
export const FETCH_USER_PROJECTS_IN_ORG = 'FETCH_USER_PROJECTS_IN_ORG';
export const FETCH_USER_PROJECTS = 'FETCH_USER_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const ENABLE_GATEWAY_ACCESS = 'ENABLE_GATEWAY_ACCESS';
export const FETCH_PROJECT_API_KEYS = 'FETCH_PROJECT_API_KEYS';
export const CREATE_PROJECT_API_KEY = 'CREATE_PROJECT_API_KEY';
export const REMOVE_PROJECT_API_KEY = 'REMOVE_PROJECT_API_KEY';

// ===========================
// SELECTORS
// ===========================
export const selectCurrentProjectId = (state) => state?.app.projectId;
export const selectCurrentProject = (state) => selectProject(state, selectCurrentProjectId(state));
export const selectProject = (state, projectId) => {
  return state.models.projects[projectId];
};
export const selectAllProjects = state => state.models.projects;

export const selectOrgProjects = (state, orgId) => {
  const orgIdForFilter = orgId || selectCurrentOrgId(state);
  return _.filter(state.models.projects, project => project.org_id === orgIdForFilter);
}

export const selectUsersForProjectsInOrg = (state, orgId) => {
  const projects = selectOrgProjects(state, orgId);
  const userIds = _.flatMap(projects, project => project.user_ids);
  return selectUsers(state, userIds);
}


// ===========================
// MODEL
// ===========================
const Project = {
  actions: {
    fetchProject: (id, params) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:id`,
        params: {
          id,
          ...params
        },
        headers: authHeaders(),
        action: FETCH_PROJECT,
        dispatch,
      });
      return _.get(result, 'body.projects.0');
    },
    fetchOrgProjects: (orgId, params) => async (dispatch, getState) => {
      const result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/organization/:orgId/projects`,
        params: {
          orgId,
          ...params
        },
        headers: authHeaders(),
        action: FETCH_ORG_PROJECTS,
        dispatch,
      });
      return _.get(result, 'body.projects');
    },
    fetchUserProjects: (userId, params) => async (dispatch, getState) => {
      const result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/user/:userId/projects`,
        params: {
          userId,
          ...params
        },
        headers: authHeaders(),
        action: FETCH_USER_PROJECTS,
        dispatch,
      });
      return _.get(result, 'body.projects');
    },
    fetchUserProjectsInOrg: (orgId, params) => async (dispatch, getState) => {
      const userId = selectCurrentUserId(getState());
      const result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/user/:userId/organization/:orgId/projects`,
        params: {
          userId,
          orgId,
          ...params
        },
        headers: authHeaders(),
        action: FETCH_USER_PROJECTS_IN_ORG,
        dispatch,
      });
      return _.get(result, 'body.projects');
    },
    createProject: (body, orgId) => async (dispatch, getState) => {
      const currentOrgId = selectCurrentOrgId(getState());
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: "/organization/:orgId/project",
        headers: authHeaders(),
        action: CREATE_PROJECT,
        dispatch,
        params: { orgId: orgId || currentOrgId },
        body: body
      });
      return _.get(result, 'body.projects.0');
    },
    editProject: (projectId, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId`,
        headers: authHeaders(),
        action: EDIT_PROJECT,
        dispatch,
        params: { projectId },
        body: body
      });
      return _.get(result, 'body.projects.0');
    },
    enableGatewayAccess: (projectId) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/:projectId/enable_gateway`,
        headers: authHeaders(),
        dispatch,
        action: ENABLE_GATEWAY_ACCESS,
        params: { projectId }
      });
    },
    fetchProjectApiKeys: (id) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/${id}/api_key/list`,
        headers: authHeaders(),
        action: FETCH_PROJECT_API_KEYS,
        dispatch,
      });
      return _.get(result, 'body');
    },
    createProjectApiKey: (id, body) => async (dispatch, getState) => {
      console.log('createProjectApiKey', id, body)
      console.log(Hosts.EDGE_CLOUD_API, `/project/${id}/api_key`, CREATE_PROJECT_API_KEY)
      let result = await post({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/${id}/api_key`,
        headers: authHeaders(),
        action: CREATE_PROJECT_API_KEY,
        dispatch,
        body: body
      });
      return _.get(result, 'body');
    },
    removeProjectApiKey: (id, name) => async (dispatch, getState) => {
      let result = await del({
        host: Hosts.EDGE_CLOUD_API,
        url: `/project/${id}/api_key/${name}`,
        headers: authHeaders(),
        action: REMOVE_PROJECT_API_KEY,
        dispatch,
      });
      return _.get(result, 'body');
    },
  },
  spec: {
    projects: {},
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    if (!_.isEmpty(body?.projects)) {
      state = {
        ...state,
        projects: {
          ...state.projects,
          ..._.keyBy(body.projects, 'id')
        },
      }
    }

    return state;
  }
}
export default Project;
