import {useEffect, useRef, useState} from "react";
import {
  ArrowSquareIcon,
  CopyIcon,
  DownBarIcon,
  EraserIcon,
  InfoIcon,
  RefreshIcon,
  SendIcon,
  SettingsIcon,
  UpBarIcon
} from "../Svg";
import {Button} from "../Button";
import _ from "lodash";
import {TextInput} from "../form/TextInput";
import cx from "classnames";
import Tooltip from "../Tooltip";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {AIShowcase} from "../../store/models/AIShowcase";
import Loader from "../Loader";
import {createSelector} from "reselect";
import {selectMobileLayout} from "../../store/UIState";

const MaxTokens = 2048;
const MinTokens = 1;
const MaxTemp = 2.0;
const MinTemp = 0.0;
const MaxTopP = 1.0;
const MinTopP = 0.1;

const defaultChatMessages = [
  {
    content: "What is Theta Network?",
    role: 'user'
  },
  {
    content: "Theta Network is the leading blockchain decentralized cloud for AI, media and entertainment. Theta Network is a \"dual network\" consisting of two complementary subsystems, the Theta Blockchain and the Theta Edge Network. The Theta blockchain provides payment, reward, and smart contract capabilities, while the edge network provides vast amounts of GPU compute power for AI, video, rendering and other tasks. Theta EdgeCloud, slated to launch in 2024 is the first hybrid cloud-edge AI computing platform powered by the Theta Edge Network with over 10,000 active global nodes. Theta blockchain supports Turing complete smart contracts, and is fully compatible with Ethereum. This EVM support enables a wide range of interesting Web3 applications to be built on the Theta Network. Theta's enterprise validator and governance council is led by Google, Samsung, Sony, Creative Artists Agency (CAA), Binance, Blockchain Ventures, DHVC, gumi and other global leaders. Theta has partnered with leading entertainment brands including Lionsgate, MGM, Katy Perry, American Idol, The Price is Right, Taste of Home, and more. Popular platforms utilizing Theta's Web3 infrastructure include MetaCannes Film3 Festival, FuseTV, CONtv Anime, WPT, PetCollective, FailArmy, and other OTT streaming services.",
    role: 'assistant'
  },
]

const SettingsPanel = ({onParamsChanged}) => {
  const [maxTokens, setMaxTokens] = useState(100);
  const [temperature, setTemperature] = useState(0.5);
  const [topP, setTopP] = useState(0.7);

  useEffect(() => {
    const rangeMaxTokens = document.getElementById('maxTokens');
    const rangeTemperature = document.getElementById('temperature');
    const rangeTopP = document.getElementById('topP');
    rangeMaxTokens.addEventListener('input', updateGradient);
    rangeTemperature.addEventListener('input', updateGradient);
    rangeTopP.addEventListener('input', updateGradient);

    updateGradient({target: rangeMaxTokens});
    updateGradient({target: rangeTemperature});
    updateGradient({target: rangeTopP});

    return () => {
      rangeMaxTokens.removeEventListener('input', updateGradient);
      rangeTemperature.removeEventListener('input', updateGradient);
      rangeTopP.removeEventListener('input', updateGradient);
    };
  }, []);

  useEffect(() => {
    onParamsChanged(maxTokens, temperature, topP)
  }, [maxTokens, temperature, topP]);

  function updateGradient(e) {
    const range = e.target;
    const value = ((range.value - range.min) / (range.max - range.min)) * 100;
    const color = `linear-gradient(to right, #129978 ${value}%, #3D4463 ${value}%)`;

    // Create a new style element
    const style = document.createElement('style');

    style.textContent = `
    #${range.id}::-moz-range-track {
      background: ${color};
    }
    #${range.id}::-webkit-slider-runnable-track {
      background: ${color};
    }
  `;

    document.head.appendChild(style);
  }

  const onSetMaxTokens = (event) => {
    const value = event.target.value;
    if (_.isEmpty(value)) {
      setMaxTokens(0)
    } else if (value < MinTokens) {
      setMaxTokens(MinTokens)
    } else if (value > MaxTokens) {
      setMaxTokens(MaxTokens)
    } else {
      setMaxTokens(value)
    }
  }

  const onSetTemperature = (event) => {
    const value = event.target.value;
    if (value < MinTemp) {
      setTemperature(MinTemp)
    } else if (value > MaxTemp) {
      setTemperature(MaxTemp)
    } else {
      setTemperature(value)
    }
  }

  const onSetTopP = (event) => {
    const value = event.target.value;
    if (_.isEmpty(value)) {
      setTopP(0)
    } else if (value < MinTopP) {
      setTopP(MinTopP)
    } else if (value > MaxTopP) {
      setTopP(MaxTopP)
    } else {
      setTopP(value)
    }
  }

  return (
    <div className="SettingsPanel">


      <div className={"SettingsPanel__InputsWrapper"}>
        <div className="SettingsPanel__field">
          <div className={"SettingsPanel__field--top"}>
            <Tooltip longText={true}
                     tooltip={"The maximum number of tokens that can be generated in the chat completion."}>
              <div className={"label-wrapper"}>
                <label htmlFor="maxTokens">max_tokens </label>
                <InfoIcon/>
              </div>
            </Tooltip>
            <TextInput max={MaxTokens} min={MinTokens} type={"number"} value={maxTokens} onChange={onSetMaxTokens}/>
          </div>
          <input type="range" id="maxTokens" max={MaxTokens} min={MinTokens} value={maxTokens}
                 onChange={onSetMaxTokens}/>
        </div>
        <div className="SettingsPanel__field">
          <div className={"SettingsPanel__field--top"}>
            <Tooltip longText={true}
                     tooltip={"Controls the randomness of the ouptput. A high temperature produces more unpredictable results and a lower temperature creates more deterministic outputs"}>
              <div className={"label-wrapper"}>
                <label htmlFor="temperature">temperature</label>
                <InfoIcon/>
              </div>
            </Tooltip>
            <TextInput step={0.1} max={MaxTemp} min={MinTemp} type={"number"} value={temperature}
                       onChange={onSetTemperature}/>
          </div>
          <input step={0.1} type="range" id="temperature" max={MaxTemp} min={MinTemp} value={temperature}
                 onChange={onSetTemperature}/>
        </div>
        <div className="SettingsPanel__field">
          <div className={"SettingsPanel__field--top"}>
            <Tooltip longText={true}
                     tooltip={"An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered."}>
              <div className={"label-wrapper"}>
                <label htmlFor="maxTokens">top_p</label>
                <InfoIcon/>
              </div>
            </Tooltip>
            <TextInput step={0.1} max={MaxTopP} min={MinTopP} type={"number"} value={topP} onChange={onSetTopP}/>
          </div>
          <input step={0.1} type="range" id="topP" max={MaxTopP} min={MinTopP} value={topP}
                 onChange={onSetTopP}/>
        </div>
      </div>
    </div>
  );
};

const ChatMessage = ({message, index, onRegenerate}) => {

  const onCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(message.content);
    toast.success("Copied to clipboard")
  }

  const onRegenerateClicked = (e) => {
    e.stopPropagation();
    onRegenerate(message, index);
  }

  return (
    <div className="ChatbotMessageContainer">
      <div className={`ChatbotMessage ChatbotMessage--${message.role}`}>
        {message.content}
        <div className={`ChatbotMessage__actions ChatbotMessage__actions--${message.role}`}>
          <Tooltip tooltip={"Copy"}>
            <div onClick={onCopy}><CopyIcon/></div>
          </Tooltip>
          {message.role === 'assistant' &&
            <Button title={"Regenerate"}
                    onClick={onRegenerateClicked}
                    color={"grey-outline"}
                    icon={<RefreshIcon/>}
            />}
        </div>
      </div>
    </div>
  )
}

const selector = createSelector(
  selectMobileLayout,
  (isMobile) => ({isMobile})
)

export const ShowcaseChatbot = ({showcase}) => {
  const dispatch = useDispatch();
  const {isMobile} = useSelector(state => selector(state));
  const [loading, setLoading] = useState(false);
  const [textareaValue, setTextareaValue] = useState('')
  const [messages, setMessages] = useState(defaultChatMessages);
  const [animatedMessage, setAnimatedMessage] = useState('');
  const [animatedMessageIndex, setAnimatedMessageIndex] = useState(null);
  const [showSettings, setShowSettings] = useState(!isMobile)
  const [resetAnimation, setResetAnimation] = useState(false)
  const [settings, setSettings] = useState({maxTokens: 50, temperature: 0.5, topP: 0.7})
  const chatboxRef = useRef(null);
  const model = showcase?.models?.[0]
  const modelName = `Model: ${showcase ? showcase?.models?.[0]?.name : '-'}`

  const onTogglePanel = () => {
    setShowSettings(!showSettings);
  }

  const sendMessage = async (event) => {
    event.preventDefault();
    if (isMobile) {
      setShowSettings(false)
    }

    const newMessage = {content: textareaValue, role: 'user'}
    setMessages([...messages,
      newMessage
    ]);

    try {
      setTextareaValue('')
      setLoading(true)
      const response = await dispatch(AIShowcase.actions.createCompletion(model, {
        messages: [...messages, newMessage],
        max_tokens: settings.maxTokens,
        temperature: settings.temperature,
        top_p: settings.topP
      }));

      const assistantResponse = response ?? "There was an issue with the response, please try again later";

      addBotMessage(assistantResponse)
    } catch (e) {
      toast.error(e.message)
      console.log(e)
    }
    setLoading(false)
  };

  const onTextareaChange = (event) => {
    setTextareaValue(event.target.value)
  }

  useEffect(() => {
    onScrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (resetAnimation) {
      if (animatedMessageIndex) {
        setMessages([...messages.slice(0, animatedMessageIndex),
          {content: animatedMessage, role: 'assistant'},
          ...messages.slice(animatedMessageIndex)
        ]);
      } else {
        setMessages([...messages,
          {content: animatedMessage, role: 'assistant'}
        ]);
      }
      setAnimatedMessage('');
      setAnimatedMessageIndex(null);
      setResetAnimation(false);
    }
  }, [resetAnimation]);

  const addBotMessage = (botMessage) => {
    let i = 0;
    const typingAnimation = setInterval(() => {
      if (i < botMessage.length) {
        setAnimatedMessage(prev => prev + botMessage[i]);
        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        i++;
      } else {
        clearInterval(typingAnimation);
        setResetAnimation(true)
      }
    }, 5);
  }

  const onScrollToBottom = () => {
    if (isMobile) {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }

  const onScrollToTop = () => {
    if (isMobile) {
      window.scrollTo(0, 0);
    } else {
      chatboxRef.current.scrollTop = 0;
    }
  }

  const onClearMessages = () => {
    setMessages([]);
  }

  const onSettingsChanged = (maxTokens, temperature, topP) => {
    setSettings({maxTokens, temperature, topP})
  }

  const regenerateMessage = async (message, index) => {
    try {
      setLoading(true)

      setMessages([...messages.slice(0, index),
        ...messages.slice(index + 1)
      ]);

      setAnimatedMessageIndex(index)

      const response = await dispatch(AIShowcase.actions.createCompletion(model, {
        messages: messages.slice(0, index),
        max_tokens: settings.maxTokens,
        temperature: settings.temperature,
        top_p: settings.topP
      }, true));

      addBotMessage(response ?? "There was an issue with the response, please try again later")
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  return (
    <div className="ShowcaseChatbot">
      <div className="ShowcaseChatbot__container">
        {showSettings && <SettingsPanel onParamsChanged={onSettingsChanged} />}

        <div className={"ShowcaseChatbot__LeftInfo"}>
          <div className={"SettingsPanel__Title"}>{modelName}</div>
          {!isMobile && <ShowOptionsButton isMobile={isMobile} onTogglePanel={onTogglePanel} showSettings={showSettings}/>}
        </div>

        <div ref={isMobile ? chatboxRef : null} className={cx("ShowcaseChatbot__chatbox", !showSettings && "ShowcaseChatbot__chatbox--no-settings")}>
          <div ref={!isMobile ? chatboxRef : null} className="ShowcaseChatbot__messages">
            {messages.map((message, index) => {
              if (animatedMessageIndex && index === animatedMessageIndex) {
                return (
                  <>
                  <div className="ChatbotMessageContainer">
                    <div className={`ChatbotMessage ChatbotMessage--${loading ? 'loader' : 'assistant'}`}>
                      {loading ? <Loader size={"small"} /> : animatedMessage}
                    </div>
                  </div>
                    <ChatMessage key={index} index={index} message={message} onRegenerate={regenerateMessage}/>
                  </>
                )
              }
              return (
                <ChatMessage key={index} index={index} message={message} onRegenerate={regenerateMessage}/>
              )
            })}
            {!_.isEmpty(animatedMessage) && (!animatedMessageIndex || animatedMessageIndex >= messages.length) && (
              <div className="ChatbotMessageContainer">
                <div className="ChatbotMessage ChatbotMessage--assistant">
                  {animatedMessage}
                </div>
              </div>
            )}
            {loading && (!animatedMessageIndex || animatedMessageIndex >= messages.length) && (
              <div className="ChatbotMessageContainer">
                <div className="ChatbotMessage ChatbotMessage--loader">
                  <Loader size={"small"}/>
                </div>
              </div>
            )}

          </div>
          <form className="ShowcaseChatbot__form" onSubmit={sendMessage}>
            <textarea name="message"
                      className="ShowcaseChatbot__input"
                      value={textareaValue}
                      onChange={onTextareaChange}
                      onKeyDown={(event) => {
                        if (event.shiftKey && event.key === 'Enter' || event.altKey && event.key === 'Enter') {
                          event.preventDefault();
                          //add new line
                          setTextareaValue(textareaValue + '\n')
                        } else if (event.key === 'Enter') {
                          event.preventDefault();
                          sendMessage(event);
                        }
                      }}
                      placeholder="Type something... Enter to send."/>

            <div className={"ShowcaseChatbot__InputActions"}>
              {isMobile && <ShowOptionsButton isMobile={isMobile} onTogglePanel={onTogglePanel} showSettings={showSettings}/>}
              <Tooltip tooltip={"Scroll to bottom"}>
                <div onClick={onScrollToBottom}><DownBarIcon/></div>
              </Tooltip>
              <Tooltip tooltip={"Scroll to top"}>
                <div onClick={onScrollToTop}><UpBarIcon/></div>
              </Tooltip>
              <Tooltip tooltip={"Clear all messages"}>
                <div onClick={onClearMessages}><EraserIcon/></div>
              </Tooltip>
            </div>

            <Button
              title={"Send"}
              type="submit"
              icon={<SendIcon/>}
              className="ShowcaseChatbot__button"
              color={"green"}
              size={"small"}
              disabled={_.isEmpty(textareaValue) || loading}/>
          </form>
        </div>
      </div>

    </div>
  );
}

const ShowOptionsButton = ({onTogglePanel, showSettings, isMobile}) => {
  return (<div
    className={cx("ShowcaseChatbot__ShowOptionsBtn", !showSettings && "ShowcaseChatbot__ShowOptionsBtn--reverse")}
    onClick={onTogglePanel}>
    {isMobile ? <SettingsIcon /> : <ArrowSquareIcon/>}
    {showSettings ? "Hide options" : "Show options"}
  </div>)
}