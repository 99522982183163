import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectCurrentOrgId } from "../../../store/models/Organization";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import { Button } from "../../../components/Button";
import User, { selectCurrentUser, selectUsersInOrg, selectUsersInProject } from "../../../store/models/User";
import { BackArrowIcon, InviteIcon } from "../../../components/Svg";
import { MemberItemModes, MemberItemsList } from "../../../components/MemberItem";
import { ModalTypes, Urls, UserRoleIds } from "../../../constants";
import UIState from "../../../store/UIState";
import { useParams } from "react-router";
import Project, { selectProject } from "../../../store/models/Project";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { mergeUrl } from "../../../utils/url";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const selector = createSelector(
  (state, projectId) => selectProject(state, projectId),
  selectCurrentOrgId,
  selectCurrentUser,
  (state) => selectUsersInOrg(state),
  (state, projectId) => selectUsersInProject(state, projectId),
  (project, currentOrgId, currentUser, usersInOrg, usersInProject) => {
    return {
      project,
      currentOrgId,
      currentUser,
      usersInOrg,
      activeUsersInProject: usersInProject.filter(u => !u.disabled),
      disabledUsersInProject: usersInProject.filter(u => u.disabled),
    }
  }
)

export const ProjectUsersPage = () => {

  const dispatch = useDispatch();
  const { projectId } = useParams();
  const {
    project, currentOrgId, currentUser, usersInOrg,
    activeUsersInProject, disabledUsersInProject
  } = useSelector(state => selector(state, projectId));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const currentUserIsAdmin = currentUser?.role === UserRoleIds.ADMIN;

  useEffect(() => {
    fetchTeamMembers()
  }, []);

  const fetchTeamMembers = async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all([
        dispatch(Project.actions.fetchProject(projectId)),
        fetchProjectUsers(),
        dispatch(User.actions.fetchProjectUsers(projectId))
      ]);
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const fetchProjectUsers = async () => {
    dispatch(User.actions.fetchProjectUsers(projectId))
  }

  const onInviteUser = () => {
    dispatch(UIState.actions.showModal(ModalTypes.INVITE_TEAM_MEMBERS_TO_PROJECT, {
      projectId,
      onDone: fetchProjectUsers
    }))
  }

  const onAddUser = (user) => {
    dispatch(User.actions.addUserToProject(user.id, projectId))
  }

  const onChangeUserRole = async (user, role) => {
    try {
      await dispatch(User.actions.changeUserRoleInProject(user.id, projectId, role))
      toast.success('Success! User role has been changed')
    } catch (e) {
      toast.error(`Error changing user role: ${e.message}`)
    }
  }

  const onRemoveUser = async (user) => {
    try {
      if (user.disabled) {
        await dispatch(User.actions.enableUserInProject(user.id, projectId))
        toast.success('Success! User has been added to this project')
      } else {
        await dispatch(User.actions.removeUserFromProject(user.id, projectId))
        toast.success('Success! User has been disabled for this project')
      }
    } catch (e) {
      toast.error(`Error removing user: ${e.message}`)
    }
  }

  const inviteUserBtn = currentUserIsAdmin ? <Button
    title={"Invite"}
    onClick={onInviteUser}
    icon={<InviteIcon />}
    color={"green-outline"}
    size={"medium"}
  /> : null;

  if (loading)
    return <Loader />

  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchTeamMembers}
        color={"green-outline"}
        title={"Retry"} />
    </div>)

  return (<div className={"TeamMembersPage"}>
    <Link to={mergeUrl(Urls.SETTINGS_PROJECTS)}>
      <div className={'TeamMembersPage__back-button'}>
        <BackArrowIcon />
        <div className={'TeamMembersPage__back-button--label'}>
          Projects
        </div>
      </div>
    </Link>
    <PageTitleBar alignTitleLeft={true} title={`${project?.name} Team`} />

    <div className={"TeamMembersPage__MembersCount project"}>
      {`Members in project (${activeUsersInProject.length})`}
      {inviteUserBtn}
    </div>

    <div className={"TeamMembers__List"}>
      <MemberItemsList users={activeUsersInProject}
        currentUser={currentUser}
        mode={MemberItemModes.MANAGE}
        onChangeRole={onChangeUserRole}
        onRemove={onRemoveUser}
      />
    </div>

    {currentUserIsAdmin && !_.isEmpty(disabledUsersInProject) && <>
      <div className={"TeamMembersPage__MembersCount"}>{`Disabled (${disabledUsersInProject.length})`}</div>

      <div className={"TeamMembers__List"}>
        <MemberItemsList users={disabledUsersInProject}
          currentUser={currentUser}
          mode={MemberItemModes.MANAGE}
          onChangeRole={onChangeUserRole}
          onRemove={onRemoveUser}
        />
      </div>
    </>
    }

    <ApiKeySection currentUser={currentUser} />
  </div>)
}

const ApiKeySection = (props) => {
  const { currentUser } = props;
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const currentUserIsViewer = currentUser?.role === UserRoleIds.VIEWER;
  const [apiKeys, setApiKeys] = useState([]);
  // const apiKeys = useSelector(state => selectProjectApiKeys(state, projectId));

  useEffect(() => {
    fetchApiKeys()
  }, [])
  const onCreateApiKey = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_API_KEY, { projectId, setApiKeys }))
  }

  const deleteApiKey = (name) => {
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, {
      title: name,
      onConfirm: async () => {
        try {
          await dispatch(Project.actions.removeProjectApiKey(projectId, name));
          let res = await dispatch(Project.actions.fetchProjectApiKeys(projectId));
          setApiKeys(res);
          toast.success('API Key is successfully deleted.');
          return;
        } catch (e) {
          toast.error(e.message)
        }
      }
    }))
  }

  const fetchApiKeys = async () => {
    setLoading(true);
    setError(null);
    try {
      let res = await dispatch(Project.actions.fetchProjectApiKeys(projectId));
      console.log('res:', res)
      setApiKeys(res);
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false);
    }
  }
  const addApiKeyBtn = currentUserIsViewer ? null : <Button
    title={"Create API Key"}
    onClick={onCreateApiKey}
    // icon={<InviteIcon />}
    color={"green-outline"}
    size={"medium"}
  />;

  if (loading)
    return <Loader />

  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchApiKeys}
        color={"green-outline"}
        title={"Retry"} />
    </div>)
  return <>
    <div className={"TeamMembersPage__MembersCount project"}>
      <p style={{ display: 'flex', alignItems: "center" }}>
        API Keys in project&nbsp;({apiKeys.length})&nbsp;
        <a
          href="https://docs.thetatoken.org/docs/edgecloud-api-keys"
          target="_blank" rel="noreferrer" className='learn-more'>
          <AiOutlineQuestionCircle size={16} />
          Learn more
        </a>
      </p>
      {addApiKeyBtn}
    </div>

    {apiKeys.length > 0 && <div className={"TeamMembersPage__List"}>
      {apiKeys.map((o, i) => <div className={"TeamMembersPage__Item-wrap"} key={i}>
        {o.name}
        {!currentUserIsViewer && <Button onClick={() => deleteApiKey(o.name)}
          color={"red-outline"}
          title={"Remove"}
          size='small' />}
      </div>)}
    </div>}
    {apiKeys.length === 0 && <div className="EmptyState project-api-keys">
      <div className="EmptyState__title">
        No API Key for this project yet.
      </div>
    </div>}
  </>
}