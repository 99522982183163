import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {selectOrganizations} from "../../../store/models/Organization";
import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import PageTitleBar from "../../../components/PageTitleBar";
import {Button} from "../../../components/Button";
import User, {
  getUserFullName,
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentUserRole
} from "../../../store/models/User";
import {EditIcon} from "../../../components/Svg";
import {ModalTypes, UserRoleIds} from "../../../constants";
import UIState from "../../../store/UIState";
import Project, {selectAllProjects} from "../../../store/models/Project";
import _ from "lodash";
import cx from "classnames";
import {UserAvatar} from "../../../components/UserAvatar";

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  (state) => selectAllProjects(state),
  (state) => selectOrganizations(state),
  (currentUserId, currentUser, projects, orgs) => {
    return {
      currentUserId,
      currentUser,
      projects: _.values(projects),
      orgs
    }
  }
)

export const ProfilePage = () => {

  const dispatch = useDispatch();
  const {currentUserId, currentUser, orgs, projects} = useSelector(state => selector(state));
  const role = useSelector(state => selectCurrentUserRole(state));

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const currentUserIsAdmin = role === UserRoleIds.ADMIN;

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all([
        dispatch(User.actions.fetchUser(currentUserId)),
        dispatch(Project.actions.fetchUserProjects(currentUserId, {expand: 'org_id'}))
      ])
    } catch (e) {
      setError(e.message)
    }
    setLoading(false);
  }

  const onEditProfile = () => {
    dispatch(UIState.actions.showModal(ModalTypes.EDIT_PROFILE))
  }


  const onEditOrg = (org) => {
    dispatch(UIState.actions.showModal(ModalTypes.EDIT_ORG, {org}))
  }
  const onEditProject = (project) => {
    dispatch(UIState.actions.showModal(ModalTypes.EDIT_PROJECT, {project}))
  }

  if (loading)
    return <Loader/>

  if (error)
    return (<div className={"ServicePage__Error"}>
      {error}
      <Button onClick={fetchData()}
              color={"green-outline"}
              title={"Retry"}/>
    </div>)

  return (<div className={"ProfilePage"}>
    <PageTitleBar alignTitleLeft={true} title={"Profile"}/>

    <div className={"ProfilePage__UserBlock"}>
      <UserAvatar user={currentUser} size={'l'}/>
      <div className={"ProfilePage__UserBlock__Info"}>
        <div className={"ProfilePage__UserBlock__Info__name"}>{getUserFullName(currentUser)}</div>
        <div className={"ProfilePage__UserBlock__Info__email"}>{currentUser?.email}</div>
      </div>
      <div className={"ProfilePage__UserBlock__Edit"} onClick={onEditProfile}>
        <EditIcon/>
      </div>
    </div>

    <div className={"ProfilePage__Block"}>
      <div className={"ProfilePage__Block__Title"}>Your organizations</div>
      <div className={'ProfilePage__Block__Items'}>
        {_.map(orgs, org => {
          const isLast = _.last(orgs) === org
          return (<div key={org.id}
                       className={cx("ProfilePage__Block__Item", `ProfilePage__Block__Item--${isLast ? 'last' : ''}`)}>
            <div className={"ProfilePage__Block__Item__Name"}>{org.name}</div>
            <div className={"ProfilePage__Block__Item__Id"}>{org.id}</div>
            {currentUserIsAdmin && <div className={"ProfilePage__Block__Item__Edit"} onClick={() => onEditOrg(org)}>
              <EditIcon/>
            </div>}
          </div>)
        })}
      </div>
    </div>
    <div className={"ProfilePage__Block"}>
      <div className={"ProfilePage__Block__Title"}>Your projects</div>
      <div className={'ProfilePage__Block__Items'}>
        {_.map(projects, project => {
          const isLast = _.last(projects) === project
          return (<div key={project.id}
                       className={cx("ProfilePage__Block__Item", `ProfilePage__Block__Item--${isLast ? 'last' : ''}`)}>
            <div className={"ProfilePage__Block__Item__Name"}>{project.name}</div>
            <div className={"ProfilePage__Block__Item__Id"}>{project.id}</div>

            {currentUserIsAdmin && <div className={"ProfilePage__Block__Item__Edit"} onClick={() => onEditProject(project)}>
              <EditIcon/>
            </div>}
          </div>)
        })}
      </div>
    </div>
  </div>)
}
