import _ from 'lodash';
import { authHeaders, del, get, post, put } from "../../utils/fetch";
import { Hosts, RequestState } from "../../constants";

// ===========================
// ACTIONS
// ===========================
export const FETCH_STANDARD_TEMPLATES = 'FETCH_STANDARD_TEMPLATES';
export const FETCH_VMS = 'FETCH_VMS';
export const CREATE_DEPLOYMENT = 'CREATE_DEPLOYMENT';
export const UPDATE_DEPLOYMENT = 'UPDATE_DEPLOYMENT';
export const CREATE_CUSTOM_TEMPLATE = 'CREATE_CUSTOM_TEMPLATE';
export const FETCH_CUSTOM_TEMPLATES = 'FETCH_CUSTOM_TEMPLATES';
export const UPDATE_CUSTOM_TEMPLATE = 'UPDATE_CUSTOM_TEMPLATE';
export const DELETE_CUSTOM_TEMPLATE = 'DELETE_CUSTOM_TEMPLATE';
export const FETCH_JUPYTER_NOTEBOOKS = 'FETCH_JUPYTER_NOTEBOOKS';
export const FETCH_DEPLOYMENTS = 'FETCH_DEPLOYMENTS';
export const DELETE_DEPLOYMENT = 'DELETE_DEPLOYMENT';
export const FETCH_DEPLOYMENT_DETAIL = 'FETCH_DEPLOYMENT_DETAIL';
export const FETCH_DEPLOYMENT_LOGS = 'FETCH_DEPLOYMENT_LOGS';
export const FETCH_PROTOTYPING_TEMPLATES = 'FETCH_PROTOTYPING_TEMPLATES';
export const FETCH_GPU_NODES = 'FETCH_GPU_NODES';
export const FETCH_TRAINING_TEMPLATES = 'FETCH_TRAINING_TEMPLATES';

// export const FETCH_USER_ORGANIZATIONS = 'FETCH_USER_ORGANIZATIONS';
// export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';

// ===========================
// SELECTORS
// ===========================

export const selectAllstandardTemplates = (state) => state?.models.standardTemplates;
export const selectNotebookTemplate = (state) => state?.models.prototypingTemplates[0];
export const selectTrainingTemplate = (state) => state?.models.trainingTemplates[0];
export const selectAllVMs = (state) => state?.models.vms;
export const selectAllCustomTemplates = (state, projectId) => state?.models.customTemplates[projectId] || [];
export const selectAllNotebooks = (state, projectId) => state?.models.jupyterNotebooks[projectId] || [];
export const selectAllDeployments = (state, projectId) => state?.models.deployments[projectId] || [];
export const selectAllGpuNodes = (state, projectId) => state?.models.gpuNodes[projectId] || [];
export const selectDeployment = (state, projectId, suffix) => {
  const deployment = _.get(state, `models.deployments.${projectId}.${suffix}`);
  if (deployment) {
    return {
      ...deployment
    };
  }
  return null;
};
export const selectTotalTempPage = (state) => state?.models.totalStandardTemplatesPages;
export const selectTotalCustomTempPage = (state) => state?.models.totalCustomTemplatesPages;
// export const selectCurrentOrg = (state) => selectOrganization(state, selectCurrentOrgId(state));
// export const selectOrganization = (state, organizationId) => {
//   return state.models.organizations[organizationId];
// };

// export const selectOrganizations = (state) => _.values(state.models.organizations);


// ===========================
// MODEL
// ===========================
const Ai = {
  actions: {
    fetchstandardTemplates: (page = 1, number = 9) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment_template/list_standard_templates`,
        params: { page: page - 1, number, category: 'serving' },
        headers: authHeaders(),
        action: FETCH_STANDARD_TEMPLATES,
        dispatch,
      });
      return selectAllstandardTemplates(getState());
    },
    fetchPrototypingTemplates: (page = 1, number = 10) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment_template/list_standard_templates`,
        params: { page: page - 1, number, category: 'prototyping' },
        headers: authHeaders(),
        action: FETCH_PROTOTYPING_TEMPLATES,
        dispatch,
      });
      return selectAllstandardTemplates(getState());
    },
    fetchTrainingTemplates: (page = 1, number = 10) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment_template/list_standard_templates`,
        params: { page: page - 1, number, category: 'training' },
        headers: authHeaders(),
        action: FETCH_TRAINING_TEMPLATES,
        dispatch,
      });
      return selectAllstandardTemplates(getState());
    },
    fetchVMs: () => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_API,
        url: `/resource/vm/list?expand=resource_ids`,
        params: {},
        headers: authHeaders(),
        action: FETCH_VMS,
        dispatch,
      });
      return selectAllVMs(getState());
    },
    createDeployment: (body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: "/deployment",
        headers: authHeaders(),
        action: CREATE_DEPLOYMENT,
        dispatch,
        body: body
      });
      // console.log('createDeployment result:', result)
      // return _.get(result, 'body.organizations.0');
    },
    updateDeployment: (shard, suffix, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment/${shard}/${suffix}`,
        headers: authHeaders(),
        action: UPDATE_DEPLOYMENT,
        dispatch,
        body: body
      });
    },
    fetchCustomTemplates: (project_id, page = 1, number = 9) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment_template/list_custom_templates`,
        params: { project_id, page: page - 1, number },
        headers: authHeaders(),
        action: FETCH_CUSTOM_TEMPLATES,
        dispatch,
      });
      return selectAllCustomTemplates(getState());
    },
    createCustomTemplate: (body) => async (dispatch, getState) => {
      let result = await post({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: "/deployment_template",
        headers: authHeaders(),
        action: CREATE_CUSTOM_TEMPLATE,
        dispatch,
        body: body
      });
      // console.log('createCustomTemplate result:', result)
      return _.get(result, 'body');
    },
    updateCustomTemplate: (id, body) => async (dispatch, getState) => {
      let result = await put({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment_template/${id}`,
        headers: authHeaders(),
        action: UPDATE_CUSTOM_TEMPLATE,
        dispatch,
        body: body
      });
      // console.log('updateCustomTemplate result:', result)
      return _.get(result, 'body');
    },
    deleteCustomTemplate: (id, project_id) => async (dispatch, getState) => {
      let result = await del({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment_template/${id}`,
        params: { project_id },
        headers: authHeaders(),
        action: DELETE_CUSTOM_TEMPLATE,
        dispatch
      });
      // console.log('deleteCustomTemplate result:', result)
      return _.get(result, 'body');
    },
    fetchJupyterNotebooks: (project_id) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment/list`,
        params: { project_id, template_name: "Jupyter Notebook" },
        headers: authHeaders(),
        action: FETCH_JUPYTER_NOTEBOOKS,
        dispatch,
      });
      return selectAllNotebooks(getState(), project_id);
    },
    fetchDeployments: (project_id) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment/list?not_template_name=Jupyter%20Notebook&not_template_name=GPU%20Node`,
        params: { project_id },
        headers: authHeaders(),
        action: FETCH_DEPLOYMENTS,
        dispatch,
      });
      return selectAllDeployments(getState(), project_id);
    },
    fetchGpuNodes: (project_id) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment/list`,
        params: { project_id, template_name: "GPU Node" },
        headers: authHeaders(),
        action: FETCH_GPU_NODES,
        dispatch,
      });
      return selectAllGpuNodes(getState(), project_id);
    },
    fetchDeploymentDetail: (project_id, shard, suffix) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment/${shard}/${suffix}`,
        params: { project_id },
        headers: authHeaders(),
        action: FETCH_DEPLOYMENT_DETAIL,
        dispatch,
      });
    },
    fetchDeploymentLogs: (project_id, shard, suffix, isFollow) => async (dispatch, getState) => {
      let result = await get({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment/${shard}/${suffix}/logs${isFollow ? '?follow=true' : ""}`,
        params: { project_id },
        headers: authHeaders(),
        action: FETCH_DEPLOYMENT_LOGS,
        dispatch,
      });
      return _.get(result, 'body');
    },
    deleteDeployment: (project_id, shard, suffix) => async (dispatch, getState) => {
      let result = await del({
        host: Hosts.EDGE_CLOUD_CONTROLLER_API,
        url: `/deployment/${shard}/${suffix}`,
        params: { project_id },
        headers: authHeaders(),
        action: DELETE_DEPLOYMENT,
        dispatch
      });
      // console.log('deleteDeployment result:', result)
      return _.get(result, 'body');
    }
  },
  spec: {
    standardTemplates: [],
    totalStandardTemplatesPages: 0,
    prototypingTemplates: [],
    customTemplates: [],
    totalCustomTemplatesPages: 0,
    vms: {},
    jupyterNotebooks: {},
    deployments: {},
    gpuNodes: {}
  },
  modelReducer: (state, type, body, action) => {
    if (action.url && action.result !== RequestState.SUCCESS)
      return state;

    // console.log('body:', body)
    // console.log('type:', type)
    if (type === FETCH_VMS) {
      return {
        ...state,
        vms: _.keyBy(body.vms, 'id')
      }
    }
    if (type === FETCH_CUSTOM_TEMPLATES) {
      const projectId = action.params.project_id;
      return {
        ...state,
        customTemplates: {
          ...state.customTemplates,
          [projectId]: body.templates
        },
        totalCustomTemplatesPages: Math.ceil(Number(body.total_count) / Number(body.number))
      }
    }
    if (type === FETCH_STANDARD_TEMPLATES) {
      return {
        ...state,
        standardTemplates: body.templates,
        totalStandardTemplatesPages: Math.ceil(Number(body.total_count) / Number(body.number))
      }
    }
    if (type === FETCH_PROTOTYPING_TEMPLATES) {
      return {
        ...state,
        prototypingTemplates: body.templates,
      }
    }
    if (type === FETCH_TRAINING_TEMPLATES) {
      return {
        ...state,
        trainingTemplates: body.templates,
      }
    }
    if (type === FETCH_JUPYTER_NOTEBOOKS) {
      const projectId = action.params.project_id;
      return {
        ...state,
        jupyterNotebooks: {
          ...state.jupyterNotebooks,
          [projectId]: _.keyBy(body, 'Suffix')
        }
      }
    }
    if (type === FETCH_DEPLOYMENTS) {
      const projectId = action.params.project_id;
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [projectId]: _.keyBy(body, 'Suffix')
        }
      }
    }
    if (type === FETCH_GPU_NODES) {
      const projectId = action.params.project_id;
      return {
        ...state,
        gpuNodes: {
          ...state.gpuNodes,
          [projectId]: _.keyBy(body, 'Suffix')
        }
      }
    }
    if (type === FETCH_DEPLOYMENT_DETAIL) {
      const projectId = action.params.project_id;
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [projectId]: {
            ...state.deployments[projectId],
            [body['Suffix']]: body
          }
        }
      }
    }

    return state;
  }
}
export default Ai;
