import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import cx from 'classnames';

import {Button} from './Button';
import _ from "lodash";
import User, {selectCurrentUser, selectCurrentUserId} from "../store/models/User";
import Loading from "../components/Loader";
import TwoFA from "../store/models/TwoFA";
import {Urls} from "../constants";
import {CheckmarkIcon} from "./Svg";
import UIState from "../store/UIState";
// import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import CodeInput from "./form/CodeInput";
import AppState from "../store/AppState";
import {pushTo} from "../store/history";
import {toast} from "react-toastify";


// ===========================
// STORE
// ===========================
const Action = {
  ENABLE_LOGIN: "ENABLE_LOGIN",
  DISABLE_LOGIN: "DISABLE_LOGIN",
  DISABLE: "DISABLE",
}

// ===========================
// STORE
// ===========================
const selector = createSelector(
  selectCurrentUserId,
  selectCurrentUser,
  (currentUserId, currentUser) => ({
    currentUserId, currentUser
  })
)

// ===========================
// COMPONENT
// ===========================
const SetupTwoFA = ({qrCode, setupKey, onSetup2FA}) => {
  const [setup2FACode, setSetup2FACode] = useState("")
  const [codeCopied, setCodeCopied] = useState(false)

  const onCopyCode = async () => {
    await navigator.clipboard.writeText(setupKey)
    setCodeCopied(true)
    setTimeout(() => {
      setCodeCopied(false)
    }, 2000)
  }

  const onSetupCodeChange = (value) => {
    setSetup2FACode(value)
  }

  const onSubmitCode = () => {
    onSetup2FA(setup2FACode)
  }

  return (
    <div className="setup-two-fa-wrapper">
      <div className="setup-app-desc">Open the authentication app on your mobile device to link it to your account.<br />
        When the app prompts you, scan the following QR code with your camera.</div>

      <div className="qr-code-container"><img className="qr-code-image" alt="2fa qr code" src={qrCode}/></div>

      <div className="setup-app-desc">If you can't scan the QR code, enter this setup key in your authentication app.</div>
      <div className="init-code-wrapper">
        <div className="init-code">{setupKey}</div>
        <div onClick={onCopyCode}>
          {codeCopied
          ? <div className={cx("copy-code", {copied: codeCopied})}><CheckmarkIcon size="s" className="checkmark-copy-icon"/>Copied!</div>
          : <div className="copy-code">Copy key</div>}
        </div>
      </div>

      <div className="get-code-desc">From the authentication app, get a code and enter it below to complete the verification process.</div>
      <div className="two-fa-code-input-wrapper">
        <CodeInput
          className="two-fa-code-input"
          name="setup-2-fa-code"
          value={setup2FACode}
          onChange={onSetupCodeChange}
        />
      </div>

      <Button color="green"
              size="l"
              className="submit-setup-2-fa-code long"
              title={"Verify"}
              disabled={_.isEmpty(setup2FACode) || setup2FACode.length !== 6}
              onClick={onSubmitCode} />
    </div>
  )
}

const SaveRecoveryCodes  = ({recoveryCodes, onRecoveryCodesSaved}) => {

  useEffect(() => {
    if (_.isEmpty(recoveryCodes)) {
      pushTo(Urls.DASHBOARD)
    }
  }, []);

  return (
    <div className="save-recovery-codes-wrapper">
      <div className="recovery-codes-description">You can now enable 2-Step authentication when you login on ThetaDrop</div>

      <div className="recovery-codes-description red">Save these recovery codes in a safe place.</div>

      <div className="recovery-codes-description">They will let you sign in if you can't access your authentication device.<br />
        You won't be able to access those codes anymore once you pass this screen.</div>

      <div className="recovery-codes-wrapper">
        {recoveryCodes && recoveryCodes.map(recoveryCode => <div key={recoveryCode} className="recovery-code-item">{recoveryCode}</div>)}
      </div>

      <Button color="green" size="l" className="submit-setup-2-fa-code long" onClick={onRecoveryCodesSaved} title={"I saved my recovery codes"} />
    </div>)
}


const EnableTwoFA = ({onDone, ...props}) => {
  const dispatch = useDispatch()
  const { currentUserId, currentUser } = useSelector(state => selector(state));
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [qrCode, setQrCode] = useState(null)
  const [setupKey, setSetupKey] = useState(null)
  const [recoveryCodes, setRecoveryCodes] = useState(null)


  useEffect(() => {
    if (!_.isNil(currentUserId)) {
      loadData()
    }
  }, [currentUserId]);

  const loadData = async () => {
    setLoading(true)
    setError(null)
    try {
      let inlineAccount = await dispatch(User.actions.fetchUser(currentUserId))
      if (!inlineAccount.otp_verified) {
        const otpSecretResponse = await dispatch(TwoFA.actions.createOtpSecret())
        setQrCode(otpSecretResponse.otp_qr_code)
        setSetupKey(otpSecretResponse.otp_secret)
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  const onTryAgain = () => loadData();

  const onSetup2FA = async (setupCode) => {
    try {
      setLoading(true)
      const recoveryCodes = await dispatch(TwoFA.actions.verify2FA(setupCode))
      setRecoveryCodes(recoveryCodes)
      // dispatch(AppState.actions.set2FARemainingTime(null))
      toast.success("2-step authentication is enabled")
      setLoading(false)
    } catch (e) {
      toast.error("2-step authentication is incorrect")
      setLoading(false)
    }
  }

  const onRecoveryCodesSaved = () => {
    setRecoveryCodes(null)
    onDone()
  }

  return (
      <div className={"g-enable-two-fa"}>
        {loading
          ? <Loading size="m" />
          : !_.isNil(error)
            ? <div className="error-message">An error happened
              <Button color="green" size="l" onClick={onTryAgain} title={"Try again"} /></div>
            : <div>
              {currentUser?.otp_verified
                ? <SaveRecoveryCodes recoveryCodes={recoveryCodes} onRecoveryCodesSaved={onRecoveryCodesSaved} />
                : <SetupTwoFA qrCode={qrCode} setupKey={setupKey} onSetup2FA={onSetup2FA} />}
            </div>}
      </div>)
}
export default EnableTwoFA;